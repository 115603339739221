import { css } from "styled-components";
export const setColor = {
  primaryColor: "#66fcf1",
  mainWhite: "#fff",
  mainBlack: "#0b0c10",
  mainGrey: "#c5c6c7",
  lightGrey: "#f7f7f7"
};

export const setFont = {
  main: "font-family: 'Noto Sans', sans-serif;"
};

export const setFlex = ({ x = "center", y = "center" } = {}) => {
  return `display:flex;align-items:${y};justify-content:${x}`;
};

export const setRem = (number = 16) => {
  return `${number / 16}rem`;
};

export const setLetterSpacing = (number = 2) => {
  return `letter-spacing:${number}px`;
};

export const setBorder = ({ width = "2px", style = "solid", color = "black" } = {}) => {
  return `border:${width} ${style} ${color}`;
};

const sizes = {
  large: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const setTransition = ({ property = "all", time = "0.3s", timing = "ease-in-out" } = {}) => {
  return `transition:${property} ${time} ${timing}`;
};
