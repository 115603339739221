import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
  box-sizing: border-box;
  max-width: 960px;
  padding: 1.5em;
  margin: 0 auto;

  @media (--media-min-small) {
    padding: 2em;
  }
`;

const CompanyAddress = styled.div`
  text-align: center;
  margin: 0 0 1rem;
`;

const SiteInfo = styled.div`
  text-align: center;
  font-size: var(--font-small-size);
  line-height: var(--font-small-line-height);

  @nest & a {
    color: inherit;
  }
`;

export default function Footer(props) {
  const companyInfo = props.companyInfo;
  return (
    <footer>
      <FooterWrapper>
        <CompanyAddress>
          {companyInfo && (
            <div>
              {companyInfo.name}
              <br />
              {companyInfo.address1}
              <br />
              {companyInfo.address2 && (
                <span>
                  {companyInfo.address2}
                  <br />
                </span>
              )}
              {companyInfo.zipCode} {companyInfo.city}
              {companyInfo.country && <span>, {companyInfo.country}</span>}
            </div>
          )}
        </CompanyAddress>

        <SiteInfo>
          © {new Date().getFullYear()}, Built with <a href="https://www.sanity.io">Sanity</a> &amp;
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </SiteInfo>
      </FooterWrapper>
    </footer>
  );
}
