import styled from "styled-components";
import { media } from "../../styles/styles";

export const Book = styled.section`
	display: grid;
  grid-template-columns: 1fr 1fr;
	column-gap: 200px;
  position: relative;
	padding-left: 120px;
	padding-right: 120px;

  @media (max-width: 1140px) {
		grid-template-columns: 1fr;
		height: auto !important;
		padding-top: 100px;
		padding-right: 20px;
		padding-left: 20px;
	}

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 50%;
    height: 100%;
    width: 100px;
		transform: translateX(-50%);
		background-image: linear-gradient(
			to left,
			rgb(255, 255, 255),
			rgb(197, 199, 201) 39%,
			rgb(134, 136, 138) 49%,
			rgb(110, 110, 110) 50%,
			rgb(134, 136, 138) 51%,
			rgb(197, 199, 201) 61%,
			rgb(255, 255, 255)
		);
		z-index: -1;

		@media (max-width: 1140px) {
			display: none;
		}
  }

	> * {
		height: 100vh;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
    width: 100%;

		@media (max-width: 1140px) {
			overflow-y: visible;
			height: auto;
		}

		> * {
			margin-top: 20px;
			margin-bottom: 50px;
		}

		&::-webkit-scrollbar {
			display: none;
		}
  }
`;

export default Book;
