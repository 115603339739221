import { createGlobalStyle } from "styled-components";
import { setColor, setFont } from "../../styles/styles";

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap')
;
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}
body{
    font-size: 21px;
    line-height: 1.2;
    color:${setColor.mainBlack};
    background:${setColor.mainWhite};
    ${setFont.main};

}
h1{
    font-size:2em;line-height:1.2;margin-bottom:0.3em
}
h2{
    font-size:1.5em;margin-bottom:0.75em
}
h3{
    font-size:1.3em;line-height:1;margin-bottom:0.5em
}
h4{
    font-size:1.2em;line-height:1.2;margin-bottom:1.25em;font-weight:bold;text-align:left;
}
h5{
    font-size:1em;margin-bottom:1.5em;font-weight:bold;
}
h6{
    font-size:1em;font-weight:bold;
}
p{
    margin:0 0 0.75em 0;
}
a{
    color:inherit;
    text-decoration: none;
}
`;

export default GlobalStyles;
