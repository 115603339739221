import React from "react";
import Header from "./globals/header";
import GlobalStyles from "../components/globals/GlobalStyles";
import "../styles/layout.css";
import styled from "styled-components";
import Footer from "./globals/footer";

const Grid = styled.div`
  display: grid;
  box-sizing: border-box;
  grid-template-areas: "header" "main";
  grid-template-rows: 0px 100vh;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1140px) {
    position: static;
    height: auto;
    display: block;
  }
`;

const MainContent = styled.div`
  grid-area: main;
`;

// The whole page layout.
const Layout = ({ children, companyInfo, onHideNav, onShowNav, showNav, siteTitle }) => (
  <React.Fragment>
    <GlobalStyles />
    <Grid>
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <MainContent>{children}</MainContent>
    </Grid>
  </React.Fragment>
);

export default Layout;
